<template>
  <nav class="navbar p-0">
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <button class="navbar-toggler navbar-toggler align-self-center" :class="isSideBarOpen ? 'open' : 'close'"
        type="button" data-toggle="minimize" @click="openSideBar">
        <i class="icon-menu"></i>
      </button>
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item dropdown px-2 mx-4">
          <a ref="notifButton" class="nav-link dropdown-toggle" data-toggle="dropdown" id="NotificationDropdown"
            @click="NotificationOpen">
            <div class="position-relative">
              <i class="fz_icon icon-bell"></i>
              <span v-if="hasNotifications" class="notification_badge"></span>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown show notification_wrpr p-0"
            ref="NotificationOpenRef" aria-labelledby="notificationDropdown" v-show="isNotificationOpen" v-closable="{
              exclude: ['notifButton'],
              handler: 'NotificationClose',
            }">
            <div class="py-2 px-3 d-flex justify-content-between notif_header">
              <span class="start">Notifications</span>
              <!-- <span role="button" class="end" @click="setAllAsRead">Mark all as read</span> -->
            </div>
            <div class="notif_body_wrpr" >
            <NotificationList  v-for="request in unreadNotifications" :f_name="request.f_name" @route="routeTo"
              :l_name="request.l_name" :key="request.id" :profilePic="request.profile_pic" :text="request.text"
              :requestTime="request.request_time" :requestDate="request.request_date" :requestedAgo="request.requested_ago"
              :link="request.link" />
              <div class="notif_plc_hldr_wrpr" v-if="!hasNotifications">
                <img src="@/assets/imgs/img/no_request_found.png" class="img-fluid notif_plc_hldr" alt="Responsive image">
              </div>
            </div>
            <div class="py-2 px-3 notif_footer">
              <span role="button" class="footer_cont" @click="routeTo('leave-request')" >See all requests</span>
            </div>
          </div>
        </li>
        <li  class="nav-item nav-profile dropdown">
          <a ref="btnProfile" class="nav-link dropdown-toggle" data-toggle="dropdown" id="profileDropdown"
            @click="ProfileSettingsOpen">
            <img v-if="user_profile_pic"  :src="user_profile_pic" alt="profile" />
            <!-- <img v-else src="../assets/imgs/img/prfl_plchlder.png" alt="profile" /> -->
            <AlphaAvatar v-else :firstName="current_user[0].f_name" :lastName="current_user[0].l_name" :size="40" />
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown show" ref="ProfileSettingsOpenRef"
            aria-labelledby="profileDropdown" v-show="isProfileSettingsOpen" v-closable="{
              exclude: ['btnProfile'],
              handler: 'ProfileSettingsClose',
            }">
            <ul class="p-0">
              <li @click="routeTo('profile')" class="dropdown-item">
                <i class="bi bi-person"></i>
                Profile
              </li>
              <li class="dropdown-item" @click="logOut">
                <i class="bi bi-power"></i>
                Logout
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import store from '@/store';
import NotificationList from './PageComponents/NotificationList.vue';
import moment from 'moment';
import LeaveServices from '@/services/LeaveServices';
import AlphaAvatar from './PageComponents/AlphaAvatar.vue';
import AuthServices from '@/services/AuthServices';
import router from '@/router';

export default {
  name: "NavbarView",
  data() {
    return {
      isNotificationOpen: false,
      isProfileSettingsOpen: false,
      isSideBarOpen: this.$store.getters.isSidebarState,
    };
  },
  methods: {
    routeTo(route) {
      this.isNotificationOpen = false;
      this.isProfileSettingsOpen = false;
      router.push({name:route});
      store.dispatch('setActiveRoute', route)
    },
    NotificationOpen() {
      this.isNotificationOpen = !this.isNotificationOpen;
    },
    ProfileSettingsOpen() {
      this.isProfileSettingsOpen = !this.isProfileSettingsOpen;
    },
    ProfileSettingsClose() {
      this.isProfileSettingsOpen = false;
    },
    NotificationClose(){
      this.isNotificationOpen = false;
    },
    openSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen;
      this.emitToSideBar(this.isSideBarOpen);
    },
    emitToSideBar(isSideBarOpen) {
      // console.log(isSideBarOpen)
      this.$emit("isSideBarOpen", isSideBarOpen);
    },
    logOut() {
      // console.log("logout");
      this.$store.dispatch("logOut");
      router.push({name:'LoginView'})
    },
    async setAllAsRead(){
      if(this.unreadNotifications.length){
        let data = {
          user_id: store.getters.getUserId,
          allNotifications:1,
        };
        const response = await AuthServices.setNotificationsAsRead(data);
        if (response.status) {
          store.dispatch("setUnreadNotifications", response.data);
        }
      }
    }
  },
  components: { NotificationList, AlphaAvatar },
  computed: {
    unreadNotifications(){
      return store.getters.getUnreadNotifications;
    },
    hasNotifications(){
      return this.unreadNotifications.length > 0 ? true : false;
    },
    user_profile_pic(){
      return store.getters.getUserProfilePic;
    },
    current_user(){
      return store.getters.getUser;
    }
  }
};
</script>
