<template>
    <!-- <div> -->
    <nav ref="secondSidebar" class="sidebar secondSidebar sidebar-offcanvas " :class="{ open: isOpen, sidebarOpen: isSidebarOpen }" id="sidebar"
    v-closable="{
              exclude: ['cmsButtonOne'],
              handler: 'hideSidebar',
            }">

        <ul class="nav" >
            <li class="nav-item" :class="{ hideMenu: checkPermission('leads', 1),active: isActive('leads',1) }">
                <a ref="cmsButton" class="nav-link" v-b-toggle.collapse-4>
                    <!-- <i class="bi bi-person-rolodex menu-icon"></i> -->
                    <span class="icon-StyleTwo-tone-46 menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                    </span>
                    <span class="menu-title">Leads</span>

                    <i class="icon-arrow-right-2-svgrepo-com ms-auto" :class="{ open: isOpenLeads }"></i>
                </a>
                <b-collapse id="collapse-4" v-model="isOpenLeads" accordion="sidebar_options">
                    <!-- <div> -->
                    <ul class="nav flex-column sub-menu">
                        <li class="nav-item" :class="{
                            active: isActive('contactus-list'),
                            hideMenu: checkPermission('contactus-list')
                        }">
                            <a class="nav-link" @click="routeTo('contactus-list')">
                                <!-- <i class="icon-phone me-2"></i> -->
                                <span class="icon-StyleTwo-tone-100 menu-icon">
                                    <span class="path1 activ_clr"></span>
                                    <span class="path2 activ_clr"></span>
                                    <span class="path3 activ_clr"></span>
                                    <span class="path4 activ_clr"></span>
                                </span>
                                Contact Us
                            </a>
                        </li>
                        <li class="nav-item" :class="{
                            active: isActive('estimate-list'),
                            hideMenu: checkPermission('estimate-list')
                        }">
                            <a class="nav-link" @click="routeTo('estimate-list')">
                                <!-- <i class="icon-file-text me-2"></i> -->
                                <span class="icon-StyleTwo-tone-85 menu-icon">
                                    <span class="path1 activ_clr"></span>
                                    <span class="path2 activ_clr"></span>
                                    <span class="path3 activ_clr"></span>
                                    <span class="path4 activ_clr"></span>
                                </span>
                                Estimate
                            </a>
                        </li>
                    </ul>
                    <!-- </div> -->
                </b-collapse>
            </li>

            <li class="nav-item" :class="{
                active: isActive('blog'),
                hideMenu: checkPermission('blog')
            }">
                <a class="nav-link" @click="routeTo('blog')">
                    <!-- <i class="bi bi-blockquote-left menu-icon"></i> -->
                    <span class="icon-document-svgrepo-com menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                        <span class="path5 activ_clr"></span>
                    </span>
                    <span class="menu-title">Blog</span>
                </a>
            </li>
            <li class="nav-item" :class="{
                active: isActive('case-study'),
                hideMenu: checkPermission('case-study')
            }">
                <a class="nav-link" @click="routeTo('case-study')">
                    <!-- <i class="bi bi-blockquote-left menu-icon"></i> -->
                    <span class="icon-StyleTwo-tone-54 menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                        <span class="path5 activ_clr"></span>
                    </span>
                    <span class="menu-title">Case Study</span>
                </a>
            </li>
            <li class="nav-item" :class="{
                active: isActive('services'),
                hideMenu: checkPermission('services')
            }">
                <a class="nav-link" @click="routeTo('services')">
                    <!-- <i class="icon-settings me-2"></i> -->
                    <span class="icon-StyleTwo-tone-23 menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                    </span>
                    <span class="menu-title">Services</span>
                </a>
            </li>

            <li class="nav-item" :class="{
                active: isActive('testimonials'),
                hideMenu: checkPermission('testimonials')
            }">
                <a class="nav-link" @click="routeTo('testimonials')">
                    <!-- <i class="icon-message-square me-2"></i> -->
                    <span class="icon-StyleTwo-tone-92 menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                        <span class="path5 activ_clr"></span>
                    </span>
                    <span class="menu-title">Testimonials</span>
                </a>
            </li>

            <li class="nav-item" :class="{
                active: isActive('portifolio-category'),
                hideMenu: checkPermission('portfolio-category')
            }">
                <a class="nav-link" @click="routeTo('portfolio-category')">
                    <!-- <i class="icon-settings me-2"></i> -->
                    <span class="icon-category-svgrepo-com menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                    </span>
                    <span class="menu-title">Portfolio Categories</span>
                </a>
            </li>

            <li class="nav-item" :class="{
                active: isActive('portfolios'),
                hideMenu: checkPermission('portfolios')
            }">
                <a class="nav-link" @click="routeTo('portfolios')">
                    <!-- <i class="icon-settings me-2"></i> -->
                    <span class="icon-StyleTwo-tone-44 menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                    </span>
                    <span class="menu-title">Portfolios</span>
                </a>
            </li>

            <li class="nav-item" :class="{
                active: isActive('clients'),
                hideMenu: checkPermission('clients')
            }">
                <a class="nav-link" @click="routeTo('clients')">
                    <!-- <i class="icon-settings me-2"></i> -->
                    <span class="icon-StyleTwo-tone-3 menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                        <span class="path5 activ_clr"></span>
                        <span class="path6 activ_clr"></span>
                        <span class="path7 activ_clr"></span>
                        <span class="path8 activ_clr"></span>
                    </span>
                    <span class="menu-title">Clients</span>
                </a>
            </li>

            <li class="nav-item" :class="{ hideMenu: checkPermission('careers', 1),active: isActive('careers',1) }">
                <a class="nav-link" v-b-toggle.collapse-3>
                    <!-- <i class="bi bi-cloud-fill menu-icon"></i> -->
                    <span class="icon-StyleTwo-tone-45 menu-icon">
                        <span class="path1 activ_clr"></span>
                        <span class="path2 activ_clr"></span>
                        <span class="path3 activ_clr"></span>
                        <span class="path4 activ_clr"></span>
                    </span>
                    <span class="menu-title">Careers</span>

                    <i class="icon-arrow-right-2-svgrepo-com ms-auto" :class="{ open: isOpenCareer }"></i>
                </a>
                <b-collapse id="collapse-3" v-model="isOpenCareer" accordion="sidebar_options">
                    <div>
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" :class="{
                                active: isActive('career-positions'),
                                hideMenu: checkPermission('career-positions')
                            }">
                                <a class="nav-link" @click="routeTo('career-positions')">
                                    <!-- <i class="icon-settings me-2"></i> -->
                                    <span class="icon-user-svgrepo-com menu-icon">
                                        <span class="path1 activ_clr"></span>
                                        <span class="path2 activ_clr"></span>
                                        <span class="path3 activ_clr"></span>
                                        <span class="path4 activ_clr"></span>
                                    </span>
                                    Career Positions
                                </a>
                            </li>

                            <li class="nav-item" :class="{
                                active: isActive('career-openings'),
                                hideMenu: checkPermission('career-openings')
                            }">
                                <a class="nav-link" @click="routeTo('career-openings')">
                                    <!-- <i class="icon-settings me-2"></i> -->
                                    <span class="icon-activity-svgrepo-com menu-icon">
                                        <span class="path1 activ_clr"></span>
                                        <span class="path2 activ_clr"></span>
                                        <span class="path3 activ_clr"></span>
                                        <span class="path4 activ_clr"></span>
                                    </span>
                                    Career Openings
                                </a>
                            </li>

                            <li class="nav-item" :class="{
                                active: isActive('career-applications'),
                                hideMenu: checkPermission('career-applications')
                            }">
                                <a class="nav-link" @click="routeTo('career-applications')">
                                    <!-- <i class="icon-settings me-2"></i> -->
                                    <span class="icon-StyleTwo-tone-7 menu-icon">
                                        <span class="path1 activ_clr"></span>
                                        <span class="path2 activ_clr"></span>
                                        <span class="path3 activ_clr"></span>
                                        <span class="path4 activ_clr"></span>
                                    </span>
                                    Career Applications
                                </a>
                            </li>
                        </ul>
                    </div>
                </b-collapse>
            </li>
        </ul>
        <span @click="hideSidebar" role="button" class="sidebar_close icon-x menu-icon p-2"></span>
    </nav>
    <!-- </div> -->
</template>
  
<script>
import router from '@/router';
import store from '@/store';

export default {
    name: "secondSideBar",
    props: ["isOpen", "isSidebarOpen"],
    data() {
        return {
            // activeItem: "dashboard",
            url_data: null,
            user_role: localStorage.getItem("user_role"),
            isOpenCms: false,
            isOpenLeads: false,
            isOpenCareer: false,
            isOpenTeams: false,
            isOpenLeave: false,
            isOpenAssets: false
        };
    },
    mounted() {
        this.url_data = this.$route.name;

        store.dispatch('setActiveRoute', this.$route.name)

        // console.log(this.$route);
        // console.log(this.permissions,'dash_perm')
    },
    methods: {
        routeTo(route) {
            this.$router.push(route);
            store.dispatch('setActiveRoute', route)

            // this.activeItem = `${route}`;
        },
        isActive(menuItem,main) {
            if(main){
            let menus = router.options.routes[1].children
            let mainMenu = menus.filter(el => (el.name == menuItem))
            let subMenus = mainMenu[0].children.map(el => el.name)
            return subMenus.some(item => item === this.$route.name)
        }
            return this.activeItem === menuItem;
        },

        checkPermission(menu, main) {
            if (main) {
                //  let menus = store.getters.getAllRoutes
                let menus = router.options.routes[1].children
                //  console.log(menus)
                let mainMenu = menus.filter(el => (el.name == menu))
                let subMenus = mainMenu[0].children.map(el => el.name)
                let permissions = this.permissions.map(item => item.menu);
                return subMenus.some(item => permissions.includes(item)) ? 0 : 1
            }
            let menus = this.permissions.filter(item => (item.menu == menu));
            if (menus.length) {
                return 0
            }
            return 1
        },
        hideSidebar(){
            this.$store.dispatch("isCmsOpen",false);
        },
    },

    computed: {
        permissions() {
            return Array.from(this.$store.getters.getPermissions)
        },
        isOpens: function (el) {
            // console.log(el);
            const a = el;
            return a;
        },
        activeItem() {
            return store.getters.getActiveRoute;
        }
    },
};
</script>
  