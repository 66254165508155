// CRm module

const state = {
    contactStreams: [],
    contactStatuses: [],
    contactPriorities:[]
  };
  
  const getters = {
    getContactStreams: (state) => {
      return state.contactStreams;
    },
    getContactStatuses: (state) => {
      return state.contactStatuses;
    },
    getContactPriorities: (state) => {
      return state.contactPriorities;
    },
  };
  
  const actions = {
    setContactStreams: ({ commit }, data) => {
      commit("SET_CONTACT_STREAMS", data);
    },
    setContactStatuses: ({ commit }, data) => {
      commit("SET_CONTACT_STATUSES", data);
    },
    setContactPriorites: ({ commit }, data) => {
      commit("SET_CONTACT_PRIORITIES", data);
    },

  };
  
  const mutations = {
    SET_CONTACT_STREAMS: (state, payload) => {
      state.contactStreams = payload;
    },
    SET_CONTACT_STATUSES: (state, payload) => {
      state.contactStatuses = payload;
    },
    SET_CONTACT_PRIORITIES: (state, payload) => {
      state.contactPriorities = payload;
    },
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  