import Config from "@/Config";
import axios from "axios";

export default {
    getHolidays(start_date,end_date){
        return axios
        .get(Config.apiGateWay.URL + "getHolidays?start_date="+(start_date || '')+'&end_date='+(end_date || ''), {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data); 
      },
    
      getHolidayById(data) {
        return axios
          .get(Config.apiGateWay.URL + "getHoliday/" + data.id, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => response.data)
          .catch((err) => err.reponse.data);
      },
    
      createHolidays(data){
        return axios
        .post(Config.apiGateWay.URL + "createHolidays", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data); 
      },
    
      editHoliday(data) {
        return axios
          .post(Config.apiGateWay.URL + "editHoliday", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => response.data)
          .catch((err) => err.response.data);
      },
    
      deleteHoliday(data) {
        return axios
          .post(Config.apiGateWay.URL + "deleteHoliday",data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => response.data)
          .catch((err) => err.response.data);
      },
      //Leave Types

      getAllLeaveTypes(){
        return axios
        .get(Config.apiGateWay.URL + "getAllLeaveTypes", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data); 
      },

      getLeaveTypes(){
        return axios
        .get(Config.apiGateWay.URL + "getLeaveTypes", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data); 
      },
    
      getLeaveTypeById(data) {
        return axios
          .get(Config.apiGateWay.URL + "getLeaveType/" + data.id, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => response.data)
          .catch((err) => err.reponse.data);
      },
    
      createLeaveType(data){
        return axios
        .post(Config.apiGateWay.URL + "createLeaveTypes", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data); 
      },

      updateLeaveTypeStatus(data){
        return axios
        .post(Config.apiGateWay.URL + "updateLeaveTypeStatus", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data);
      },
    
      editLeaveType(data) {
        return axios
          .post(Config.apiGateWay.URL + "editLeaveType", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => response.data)
          .catch((err) => err.response.data);
      },
    
      deleteLeaveType(data) {
        return axios
          .post(Config.apiGateWay.URL + "deleteLeaveType/" + data.id, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => response.data)
          .catch((err) => err.response.data);
      },

      // leave allocations

      getLeaveAllocations(){
        return axios
        .get(Config.apiGateWay.URL + "getLeaveAllocations", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data); 
      },

      createLeaveAllocations(data){
        return axios
        .post(Config.apiGateWay.URL + "createLeaveAllocations",data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.response.data); 
      },

      getLeaveAllocationByUserId(data) {
        return axios
          .get(Config.apiGateWay.URL + "getLeaveAllocation/" + data.id, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => response.data)
          .catch((err) => err.reponse.data);
      },

      updateLeaveAllocation(data){
        return axios
        .post(Config.apiGateWay.URL + "updateLeaveAllocation",data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.reponse.data);
      },

      deleteLeaveAllocations(data){
        return axios
        .post(Config.apiGateWay.URL + "deleteLeaveAllocation/"+ data.id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.reponse.data);
      },

      //Leave Request

      getLeaveRequestById(data){
        return axios
        .post(Config.apiGateWay.URL + "getLeaveRequestById/" + data.id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.reponse.data);
      },

      getLeaveRequestByUserId(data){
        return axios
        .post(Config.apiGateWay.URL + "getLeaveRequestByUserId", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.reponse.data);
      },

      // setAllAsRead(data){
      //   return axios
      //   .post(Config.apiGateWay.URL + "setLeaveRequestAsRead", data, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   })
      //   .then((response) => response.data)
      //   .catch((err) => err.reponse.data);
      // },

      createLeaveRequest(data){
        return axios
        .post(Config.apiGateWay.URL + "createLeaveRequest",data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.reponse.data);
      },

      updateRequestStatus(data){
        return axios
        .post(Config.apiGateWay.URL + "updateRequestStatus",data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => response.data)
        .catch((err) => err.reponse.data);
      }
}