// Users module
// import axios from "axios";

const state = {
  allBlogs: [],
  blogById: {},
  blog_categories:[],
  comments:[],
};

const getters = {
  getBlogs: (state) => {
    return state.allBlogs;
  },
  getBlogById: (state) => {
    return state.blogById;
  },
  getComments: (state) => {
    // console.log(state.comments)
    return state.comments;
  },
  getBlogCategories: (state) => {
    // console.log(state.comments)
    return state.blog_categories;
  }

};

const actions = {
  getAllBlogs: ({ commit }, blogs) => {
    commit("SetBlogs", blogs);
  },
  createBlog: ({ commit }, blogs) => {
    commit("SetBlogs", blogs);
  },
  getBlogById: ({ commit }, blog) => {
    commit("SetBlogById", blog);
  },
  deleteBlog: ({ commit }, blogs) => {
    commit("SetBlogs", blogs);
  },
  setComments:({ commit }, comments) => {
    commit("SET_COMMENTS", comments);
  },
  setBlogCategories:({ commit }, categories) => {
    commit("SET_BLOG_CATEGORIES", categories);
  },
};

const mutations = {
  // login: (state, payload) => (state.user = payload.data),
  SetBlogs: (state, payload) => {
    // console.log(payload, "payload");
    state.allBlogs = payload;
  },

  SetBlogById: (state, payload) => {
    // console.log(payload, "payload");
    state.blogById = payload;
  },
  
  SET_COMMENTS: (state, payload) => {
    // console.log(payload)
    state.comments = payload;
  },
  SET_BLOG_CATEGORIES: (state, payload) => {
    // console.log(payload)
    state.blog_categories = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
