<template>
  <div class="layout" :class="!isSideBarOpen ? 'sidebar-icon-only' : ''">
    <!-- <NavBar @isSideBarOpen="updateSideBarOpen" /> -->

    <div class="evalayout_page_wrpr">
      <SideBar :isOpen="isSideBarOpen" 
      :isCmsOpen="isCmsOpen"
      :isTeamsOpen="isTeamsOpen"
      :isLeaveOpen="isLeaveOpen"
      :isCrmOpen="isCrmOpen"
      @isTeamsOpen="updateTeamsOpen" 
      @isCmsOpen="updateCmsOpen"
      @isLeaveOpen="updateLeaveOpen"
      @isCrmOpen="updateCrmOpen" />
      <!-- <SecondSidebar :isOpen="isCmsOpen" :isSidebarOpen="isSideBarOpen"  /> -->

      <div class="main_panel">
        <NavBar @isSideBarOpen="updateSideBarOpen" />
        <div class="content-wrapper">
          <router-view />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import SideBar from "../components/SideBar.vue";
import Footer from "../components/Footer.vue";
import SecondSidebar from "@/components/SecondSidebar.vue";

export default {
  name: "dashoardView",
  components: {
    NavBar,
    SideBar,
    Footer,
},

  data() {
    return {
      // isSideBarOpen: true,
    };
  },

  computed: {
    isSideBarOpen() {
      return this.$store.getters.isSidebarState;
    },
    isCmsOpen(){
      return this.$store.getters.isCmsOpen;
    },
    isTeamsOpen(){
      return this.$store.getters.isTeamsOpen;
    },
    isLeaveOpen(){
      return this.$store.getters.isLeaveOpen;
    },
    isCrmOpen(){
      return this.$store.getters.isCrmOpen;
    }
  },

  methods: {
    updateSideBarOpen(isOpen) {
      this.$store.dispatch("sidebarState", isOpen);
    },
    updateCmsOpen(isCmsOpen){
      this.$store.dispatch("isCmsOpen",isCmsOpen);
    },
    updateTeamsOpen(isTeamsOpen){
      this.$store.dispatch("isTeamsOpen",isTeamsOpen);
    },
    updateLeaveOpen(isLeaveOpen){
      this.$store.dispatch("isLeaveOpen",isLeaveOpen);
    },
    updateCrmOpen(isCrmOpen){
      this.$store.dispatch("isCrmOpen",isCrmOpen);
    },
  },
};
</script>
