<template>
    <div class="alpha_avatar" :style="[{ backgroundColor: bgColor }, styleObject]">
        <span>{{ initials }}</span>
    </div>
</template>
<script>
export default {
    props: {
        lastName: {
            type: String,
            required: true,
            default: "",
        },
        size: Number,
        firstName: {
            type: String,
            required: true,
            default: "",
        }
    },
    data() {
        return {
            colors: ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e",
                "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f",
                "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"],
            styleObject: {
                width: this.size + 'px',
                height: this.size + 'px',
                lineHeight: this.size + 'px',
                fontSize: ((this.size) / 2.5) + "px"
            }
        }
    },
    computed: {
        initials() {
            const firstInitial = this.firstName.charAt(0).toUpperCase();
            const lastInitial =  this.lastName.charAt(0).toUpperCase();
            return `${firstInitial}${lastInitial}`;
        },
        bgColor() {
            const firstInitial =  this.firstName.charAt(0).toUpperCase();
            let charIndex =  firstInitial.charCodeAt(0) - 65;
            let colorIndex = charIndex % 19;
            // console.log(charIndex, colorIndex, this.colors[colorIndex]);
            return this.colors[colorIndex]
        }
    }
}
</script>