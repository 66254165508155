const state = {
    allDepartments:[],
    allJobCategories: [],
    allEmployeeLevels: [],
    directReportingPersons: [],
    managementReportingPersons: [],
    hrReportingPersons: [],
}

const getters = {
    getAllDepartments: (state) => {
        return state.allDepartments;
    },
    getAllJobCategories: (state) => {
        return state.allJobCategories;
    },
    getAllEmployeeLevels: (state) => {
        return state.allEmployeeLevels;
    },
    getDirectReportingPersons: (state) => {
        return state.directReportingPersons;
    },
    getManagementReportingPersons: (state) => {
        return state.managementReportingPersons;
    },
    getHrReportingPersons: (state) => {
        return state.hrReportingPersons;
    },


}

const actions = {

    setAllDepartments: ({ commit }, data) => {
        commit("SET_ALL_DEPARTMENTS", data);
    },

    setAllJobCategories: ({commit}, data) => {
        commit("SET_ALL_JOB_CATEGORIES",data);
    },

    setAllEmployeeLevels: ({ commit }, data) => {
        commit("SET_ALL_EMPLOYEE_LEVELS", data);
    },

    setDirectReportingPerson: ({ commit }, data) => {
        commit("SET_DIRECT_REPORTING_PERSONS", data);
    },

    setManagementReportingPersons: ({ commit }, data) => {
        commit("SET_MANAGEMENT_REPORTING_PERSONS", data);
    },

    setHrReportingPersons: ({ commit }, data) => {
        commit("SET_HR_REPORTING_PERSONS", data);
    },
}

const mutations = {

    SET_ALL_DEPARTMENTS: (state,payload) => {
        state.allDepartments = payload;
    },

    SET_ALL_JOB_CATEGORIES: (state, payload) => {
        state.allJobCategories = payload;
    },

    SET_ALL_EMPLOYEE_LEVELS: (state,payload) => {
        state.allEmployeeLevels = payload
    },

    SET_DIRECT_REPORTING_PERSONS: (state,payload) => {
        state.directReportingPersons = payload
    },

    SET_MANAGEMENT_REPORTING_PERSONS: (state,payload) => {
        state.managementReportingPersons = payload
    },

    SET_HR_REPORTING_PERSONS: (state,payload) => {
        state.hrReportingPersons = payload
    },

}

export default {
    state,
    getters,
    actions,
    mutations,
};
