import Config from "@/Config";
import axios from "axios";

export default {
  login(credentials) {
    return axios
      .post(Config.apiGateWay.URL + "login", credentials, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  },
  getPermissions(id){
    return axios
      .post(Config.apiGateWay.URL + 'userPermissions/' + id,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  },
  editUserPermissions(data){
    return axios
      .post(Config.apiGateWay.URL + 'editUserPermissions',data,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
  },
  getUnreadNotifications(data){
    return axios
    .post(Config.apiGateWay.URL + 'getUnreadNotifications',data,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response.data)
    .catch((err) => err.response.data);
  },
  setNotificationsAsRead(data){
    return axios
    .post(Config.apiGateWay.URL + 'setNotificationsAsRead',data,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response.data)
    .catch((err) => err.response.data);
  },

};
