export default {
    msCreds: {
        TENANT_ID:'118c74d8-ec5e-480a-b924-7cb457407025',
        CLIENT_ID:'b9350ca8-1de1-480b-9ec3-7b7c57f4aa5e',
        AUTHORITY:'https://login.microsoftonline.com/118c74d8-ec5e-480a-b924-7cb457407025',
        REDIRECT_URI:'https://evaadmin.evalogical.com/blank.html',
        SCOPES:'["User.Read"]'
    },
  };

